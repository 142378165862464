import React from 'react';
import styled, { css } from 'react-emotion';
import t from '@jetshop/intl';
import Container from './Layout/Container';
import { TrendLink } from './ui/Button';
import HelloRetailRecommendations from '../components/HelloRetail';

const ErrorContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ErrorTitle = styled('span')`
  color: ${({ theme }) => theme.colors.black};
  font-family: ${({ theme }) => theme.fonts.primary};
  font-weight: 700;
`;

const ErrorContent = styled('span')`
  width: 100%;
  max-width: 500px;
  margin: ${({ theme }) => theme.margin.sm} 0px;
  color: ${({ theme }) => theme.colors.black};
  font-family: ${({ theme }) => theme.fonts.primary};
  font-weight: 700;
`;

const ErrorBody = styled('ul')`
  width: 100%;
  max-width: 500px;
  margin-bottom: ${({ theme }) => theme.margin.sm};
  color: ${({ theme }) => theme.colors.grey};
  font-family: ${({ theme }) => theme.fonts.primary};
  font-style: normal;
  ${({ theme }) => theme.fontSizes.description}
`;

const Li = styled('li')`
  margin-bottom: 10px;
  strong {
    font-weight: bold;
  }

  ${({ theme, disc }) =>
    disc &&
    css`
      position: relative;
      padding-left: 15px;

      &::before {
        display: block;
        content: '';
        position: absolute;
        top: 7px;
        left: 0px;
        width: 5px;
        height: 5px;
        border-radius: 50%;
        background-color: ${theme.colors.black};
      }
    `}
`;

const Link = styled(TrendLink)`
  width: auto;
`;

class NotFoundPage extends React.PureComponent {
  render() {
    return (
      <>
        <ErrorContainer maxWidth="small" marginTop="sm">
          <ErrorTitle className="mega-size">{t('Whoops')}</ErrorTitle>
          <ErrorContent className="sub-heading-size">
            {t(`You have attempted to download a page that does not exist on Jetshop's
          server.`)}
          </ErrorContent>
          <ErrorBody>
            {' '}
            <Li>
              <strong>{t('This could be due to the following:')}</strong>
            </Li>
            <Li disc={true}>
              {t(
                'The address provided is incorrect. Please check the spelling and try again.'
              )}
            </Li>
            <Li disc={true}>
              {t(
                'You are trying to use an old address that no longer exists or that may have changed.'
              )}
            </Li>
            <Li disc={true}>
              {t(
                'You may have used an old bookmark. If you are sure that you have used the correct address to the site, the problem may be caused by a temporary service disruption. Please try again later.'
              )}
            </Li>
            <Li>
              {t(
                'NOTE! If you were directed here via a link we are grateful if you inform us as to which link it was so that we can resolve the problem.'
              )}
            </Li>
          </ErrorBody>
          <Link to="/">{t('HOMEPAGE')}</Link>
        </ErrorContainer>
        <HelloRetailRecommendations
          recommendationsList={[
            {
              title: 'Rekommenderat för dig',
              recommendationId: 'k6203b41622cae73d2f62ef9a'
            },
            {
              title: 'Populärt just nu',
              recommendationId: 'k6203b41622cae73d2f62ef9c'
            }
          ]}
        />
      </>
    );
  }
}

export default NotFoundPage;
